.modalOverlay {
    display: none;
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.3);
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 99;

    &__close {
        display: flex;
        width: 100%;
        top: 0;
        justify-content: right;
        color: var(--primary);
        text-decoration: underline;

        & > div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            margin-right: 5px;
            cursor: pointer;
        }

        svg {
            position: relative;
            overflow: visible;
        }
    }

    &--isVisible {
        display: flex;
    }

    &--isPartialModal {
        position: absolute;
        height: 100%;
        width: 100%;
    }
}

.modalOverlay__content {
    position: absolute;
    padding: 20px 0;
    background: white;
    width: 100%;
    max-width: 550px;
    min-height: 300px;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
}

.modalOverlay__content--isCaller {
    padding: 0;

    @media (orientation: landscape) {
        max-width: 700px;
    }
}
