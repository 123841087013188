.invitation {
    position: relative;
    width: 100%;
    padding: 5px;

    &__add-recipient__container {
        display: flex;
        flex-direction: column;
        margin: 0 20px;
        color: black;
        margin-bottom: 20px;

        .invitation__header {
            margin: 10px 0 0 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 430px;

            span {
                &:nth-child(1) {
                    position: relative;
                    margin-left: 40px;
                }
            }

            small {
                font-size: 0.75rem;
            }

            & > small {
                color: #b9b9b9;
            }
        }

        .loading-container {
            position: relative;
            translate: 25% -25%;
            width: 30px;

            .lds-ring {
                translate: -3px 0;
            }
        }

        .add-recipient-field {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin: 20px 20px 10px 10px;

            .recipient-wrapper {
                p {
                    text-align: left;
                    font-size: 12px;
                    padding-top: 5px;
                    color: #b9b9b9;
                    font-weight: 400;
                }
            }

            input {
                width: 350px;

                &::placeholder {
                    color: #b9b9b9;
                }
            }

            &--hasError {
                position: relative;
                span {
                    position: absolute;
                    bottom: 0;
                    translate: 0 100%;
                    font-size: x-small;
                    color: red;
                }
                input {
                    border: 2px solid red;
                }
                input:focus {
                    border: 2px solid red !important;
                }
            }

            & > div {
                button {
                    margin: 0;
                    translate: 10px -25%;
                    height: 30px;
                    width: 30px;

                    svg {
                        position: absolute;
                        right: 50%;
                        top: 50%;
                        translate: 50% -50%;
                    }
                }
            }
        }
    }

    &__add-description-field {
        position: relative;
        width: 100%;
        padding: 0 70px;
        flex-wrap: wrap;
        display: flex;
        flex-direction: column;

        textarea {
            border-right: none;
            border-left: none;
            border-bottom: 3px dotted #dfdfdf;
            border-top: 3px dotted #dfdfdf;
            resize: none;
            padding: 10px;
            height: 150px;
            font-family: inherit;
            color: var(--font_color);
            
            &::placeholder {
                color: #b9b9b9;
            }
        }

        textarea:focus {
            border-bottom: 3px dotted #dfdfdf00;
            border-top: 3px dotted #dfdfdf00;
            outline: 1px solid var(--primary);
        }

        textarea::placeholder {
            font-family: inherit;
            font-weight: inherit;
        }

        label {
            display: flex;
            justify-content: flex-end;
            color: #b9b9b9;
        }

        &--hasError {
            margin: 0;
            span {
                font-size: x-small;
                position: absolute;
                translate: 0 160px;
                color: red;
            }

            textarea {
                border-bottom: 3px dotted #ffffff00;
                border-top: 3px dotted #ffffff00;
                outline: 1px solid red;
            }

            textarea:focus {
                outline: 1px solid red;
            }
        }
    }

    .button__container {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;

        button {
            position: relative;
            display: flex;
            justify-content: space-around;
            min-width: 140px;

            span {
                font-size: inherit;
                color: inherit;
            }
        }

        button:nth-child(1) {
            svg {
                height: 20px;
                width: 20px;
                translate: 0 2px;
            }
        }

        button:nth-child(2) {
            svg {
                height: 25px;
                width: 25px;
            }
        }

        button:hover {
            svg {
                path {
                    fill: var(--primary);
                }
                rect {
                    fill: var(--primary);
                }
            }
        }
    }

    .alert-panel {
        .link-copied {
            position: absolute;
            opacity: 1;
            right: 50%;
            translate: 50% -60px;
            width: 250px;
            text-align: center;
            transition: opacity 0.3s;
            z-index: 2;

            &--isHidden {
                opacity: 0;
                z-index: 1;
            }
        }
    }
}

@keyframes grow {
    0% {
        scale: 1;
    }

    100% {
        scale: 1.2;
    }
}
