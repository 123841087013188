.disclaimer-panel {
    padding: 30px;
    font-weight: 400;

    ul, ol {
        padding: 1.2rem;
    }

    ol {
        list-style-type: decimal;
    }

    ul {
        list-style: inherit;
    }

    h3 {
        font-size: 1.25rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }

    &__text {
        max-height: 265px;
        overflow: auto;
        margin: 4px 0 32px 0;
        line-height: 1.5;

        p {
            margin-bottom: 0.25rem;
        }
    }

    &__confirmation {
        display: flex;
        align-items: center;

        input[type="checkbox"] {
            -webkit-appearance: none;
            appearance: none;
            background-color: #dbdbdb;
            width: 25px;
            height: 25px;
            border: 2px solid #dbdbdb;
            border-radius: 5px;
            display: grid;
            place-content: center;
            margin: -2px 8px 0 0;
        }

        input[type="checkbox"]::before {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='a' viewBox='0 0 120 120'%3E%3Cpath d='m49.38,89c-.11,0-.23,0-.34,0-2.17-.11-4.14-1.29-5.26-3.14l-12.84-21.21c-1.86-3.08-.86-7.08,2.23-8.93,3.09-1.86,7.11-.87,8.98,2.22l7.85,12.96,28.25-37.29c2.17-2.87,6.28-3.45,9.16-1.28,2.89,2.16,3.46,6.24,1.29,9.11l-34.08,44.99c-1.24,1.63-3.18,2.59-5.22,2.59Z' fill='white' /%3E%3C/svg%3E");
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            width: 25px;
            height: 25px;
        }

        input[type="checkbox"]:checked::before {
            transform: scale(1);
        }

        input[type="checkbox"]:checked {
            background-color: var(--primary);
        }
    }

    &__hint {
        border-top: 1px solid var(--primary);
        padding-top: 20px;
        font-weight: bold;
    }

    &__button-container {
        margin-top: 32px;
        display: flex;
        justify-content: center;

        button {
            margin: 0 16px;
        }
    }
}

.disclaimer-panel--caller {
    line-height: 1.5rem;
}
