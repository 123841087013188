.logView {
    margin-top: 50px;
    background: var(--element_background_color);
    padding: 20px 10px;
    text-align: center;

    &__show {
        display: inline-block;
    }

    &__list {
        max-height: 800px;
        overflow: scroll;
        text-align: left;
    }

    &__entry {
        span {
            display: inline-block;
            margin: 2px 5px;
        }
        pre {
            display: inline-block;
        }

        span:nth-child(1) {
            color: darkblue;
        }
        span:nth-child(2) {
            color: black;
        }
        span:nth-child(3) {
            color: darkgreen;
        }
    }

    &__object {
        cursor: pointer;

        &--error {
            color: darkred !important;
        }
    }
}