.audio-panel {
    display: flex;
    flex-direction: column;

    .dispatcher-controls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px;

        &__container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            div {
                width: 30px;

                &:nth-child(2) {
                    width: 60px;
                }

                svg {
                    height: 22px;
                }
            }

            #nf-bubble,
            #ls-dots {
                display: none;
            }
        }
    }
    .caller-controls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px;
        background-color: #efefef;

        &__container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            div {
                width: 30px;
                svg {
                    height: 100%;
                }
            }
        }
    }

    .invited-user-controls {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        li {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 10px;

            .invited-user-controls__container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                div {
                    width: 30px;
                    svg {
                        height: 100%;
                        fill: var(--primary);
                    }
                }
            }
        }

        // if more than 10 users attempt to join, they will be immediately kicked out, so don't display these users
        li:nth-child(n + 11) {
            display: none;
        }

        .is-leaving {
            opacity: 0.4;
            pointer-events: none;
            cursor: not-allowed;
        }

        li:nth-child(2n) {
            background-color: #efefef;
        }
    }

    svg {
        fill: var(--primary);
        width: 18px;
    }

    .controls {
        &--audio,
        &--kick-user,
        &--mic {
            cursor: pointer;
        }

        &--disabled {
            opacity: 0.3;
            cursor: not-allowed;
        }
    }

    &--isDisabled {
        .controls {
            &--audio,
            &--mic {
                opacity: 0.3;
                cursor: not-allowed;
            }
        }
    }

    .connection-quality {
        &--0 {
            svg {
                rect {
                    fill: #a6a6a6;
                }
            }
        }

        &--1 {
            svg {
                rect {
                    &:nth-child(1) {
                        fill: var(--primary);
                    }
                    fill: #a6a6a6;
                }
            }
        }

        &--2 {
            svg {
                rect {
                    &:nth-child(1),
                    &:nth-child(2) {
                        fill: var(--primary);
                    }
                    fill: #a6a6a6;
                }
            }
        }

        &--3 {
            svg {
                rect {
                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3) {
                        fill: var(--primary);
                    }
                    fill: #a6a6a6;
                }
            }
        }

        &--4 {
            svg {
                rect {
                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4) {
                        fill: var(--primary);
                    }
                    fill: #a6a6a6;
                }
            }
        }

        &--5 {
            svg {
                rect {
                    fill: var(--primary);
                }
            }
        }
    }
}
