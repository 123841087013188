@import '../../settings.scss';

.chatMessage {
    @include clearfix();
    margin: 5px 0;
    text-align: left;
    position: relative;

    &__meta {
        display: flex;
        align-items: center;
        text-align: right;
        z-index: 1;

        span {
            font-weight: 400;
        }

        .chatMessage--right & {
            justify-content: flex-end;
            text-align: right;
        }
    }

    &__time {
        display: block;
        z-index: 1;
        color: $grey_dark;

        order: 2;
        .chatMessage--right & {
            order: 1;
        }
    }

    &--dispatcher-only {
        margin: 10px 0;
        color: #dbdbdb;
        border-bottom: 3px #dbdbdb dotted;
        text-align: center;
    }

    &__message {
        word-wrap: break-word;
        position: relative;
        display: inline-block;
        background: var(--chat_background_left);
        padding: 10px 15px;
        border-radius: var(--border_radius_super_narrow);
        margin: 0;
        margin-left: 20px;
        z-index: 2;
        min-width: 60%;
        max-width: 95%;
        width: auto;
        height: auto;
        padding: 14px 14px;

        &::after {
            content: ' ';
            position: absolute;
            width: 0;
            height: 0;
            left: -20px;
            right: auto;
            top: 0px;
            bottom: auto;
            border: 22px solid;
            border-color: var(--chat_background_left) transparent transparent transparent;
        }

        .chatMessage--right & {
            min-width: 40%;
            max-width: 95%;
            float: right;
            background: var(--chat_background_right);
            margin-right: 20px;

            &::after {
                content: ' ';
                position: absolute;
                width: 0;
                height: 0;
                left: auto;
                right: -20px;
                top: 0px;
                bottom: auto;
                border: 20px solid;
                border-color: var(--chat_background_right) transparent transparent transparent;
            }
        }

        .chat-file-preview {
            display: flex;
            width: 100%;
            margin-bottom: 10px;

            .chat-file-icons {
                display: flex;
                height: 80px;
                background-color: #ffffff;
                min-width: 80px;
                border-radius: var(--border_radius_narrow);
                border: 1px solid $grey;
                padding: 10px;
                pointer-events: none;
                cursor: pointer;

                svg {
                    padding: 8px;
                    max-width: 100px;
                    cursor: pointer;
                }

                svg:nth-child(1) {
                    background-color: var(--primary);
                    fill: #ffffff;
                    border-radius: 50px;
                }

                svg:nth-child(2) {
                    position: absolute;
                    width: 28px;
                    border-radius: 50px;
                    padding: 5px;
                    background-color: #000000;
                    fill: #ffffff;
                    top: 5px;
                    left: 80px;
                    z-index: 1;
                    pointer-events: all;
                    cursor: pointer;
                }
            }

            .chat-file-info {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                min-width: 50px;
                padding: 10px 0 0 10px;
            }

            .chat-file-info > span {
                font-size: 0.85rem;
            }

            .chat-file-description {
                margin-top: 10px;
            }

            span:nth-child(2) {
                line-height: 2;
            }
        }
    }

    &__showOriginal {
        position: absolute;
        background: var(--chat_background_left);
        border-radius: var(--border_radius_super_narrow);
        padding: 10px;
        right: 0;
        top: 10px;

        transform: translateY(-100%);
        font-size: $font_smaller;
        opacity: 0.5;
        cursor: pointer;

        font-weight: 400;

        &--strike {
            text-decoration: line-through;
        }

        .chatMessage--right & {
            background: var(--chat_background_right);
            left: 0;
            right: auto;
        }
    }

    &--isInit {
        .chatMessage__message {
            animation-delay: 0.3s;
            animation-duration: 1s;
            animation-iteration-count: 4;
            animation-name: blink-animation-chat;

            &::after {
                animation-delay: 0.3s;
                animation-duration: 1s;
                animation-iteration-count: 4;
                animation-name: blink-animation-chat-left;
            }
        }
    }
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

@keyframes blink-animation-chat {
    0% {
        background-color: var(--chat_background_left);
        color: $grey_dark;
    }

    50% {
        background-color: var(--secondary);
        color: var(--text_on_secondary);
    }
}

@keyframes blink-animation-chat-left {
    0% {
        border-color: var(--chat_background_left) transparent transparent transparent;
    }

    50% {
        border-color: var(--secondary) transparent transparent transparent;
    }
}
