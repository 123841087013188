.drone-panel {
    min-height: 483px;
    position: relative;
    display: block;
    justify-content: center;
    align-items: center;

    video {
        max-width: 100%;

        .dispatcherDashboard & {
            max-height: 480px;
            min-height: 480px;
        }
    }

    &__placeholder {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            max-width: 200px;
            max-height: 200px;
            width: 70%;
            height: 70%;
        }
    }

    &--isHidden {
        display: none;
    }
}