.smart-connect {
    & > div {
        position: relative;
        display: flex;
        flex-direction: column;

        .btn {
            margin-top: 10px;
        }
    }

    &__features {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            svg {
                fill: var(--primary);
            }
        }
    }
}
