.form {
    &__group {
        margin: 15px 0;

        :first-child {
            span {
                font-weight: 700;
                white-space: nowrap;
            }
        }
    }

    #id {
        font-weight: 700 !important;
    }
}

input[type='checkbox'] {
    margin-right: 10px;
}

input[type='text'],
input[type='tel'],
input[type='phone'],
input[type='password'],
input[type='email'] {
    display: block;

    width: 100%;
    margin: 0;

    line-height: 1.5;
    padding: var(--input_padding);

    border: var(--border_width) var(--border_style) $grey;
    border-radius: var(--border_radius);

    font-size: $font_small;
    text-align: start;
    font-family: var(--font);
    font-weight: var(--font_weight);
    cursor: text;

    background-color: white;
    background-clip: padding-box;
    color: var(--font_color);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    box-shadow: none;

    &:focus {
        background-color: white;
        color: var(--font_color);
        border-color: var(--primary);
        outline: 0;
        box-shadow: 0 0 3px 1px var(--primary);
    }

    &::placeholder {
        color: $grey;
    }

    &:disabled {
        background-color: #efefef;
        cursor: not-allowed;
    }
}

label {
    display: block;
    margin: 5px 0;
}

select {
    min-width: 150px;
    max-width: 100%;
}
