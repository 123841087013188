@import '../../settings.scss';

.connectionOverlay {
  display: block;
  position: absolute;
  z-index: 5;
  text-align: center;

  background: rgba(white,0.4);

  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  &--inactive,
  &--lostConnection {
    display: none;
  } 

  &--forced {
    background: rgba(white, .2);
  }

  & > div {
    display: flex;
    align-items: center;
    border-radius: var(--border_radius);
    padding: 10px;
    background: white;
    position: absolute;
    min-width: 230px;
    left: 50%;
    top: 50%;

    transform: translate(-50%, -50%);
    
    span {
      display: inline-block;
      margin-right: 10px;
    }
  }
}