// FONT IMPORT
@font-face {
    font-family: 'TT Rounds Condensed';
    font-style: normal;
    font-weight: 600;
    src: url('./resources/TTRoundsCondensed-Regular.otf');
}

@font-face {
    font-family: 'TT Rounds Condensed';
    font-style: normal;
    font-weight: 700;
    src: url('./resources/TTRoundsCondensed-Bold.otf');
}

// RESET
@import 'reset';

// BOOTSTRAP
// @import url('https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css');

// SETTINGS
@import 'settings';

@import './defaultStyles/grid';
@import './defaultStyles/buttons';
@import './defaultStyles/forms';
@import './defaultStyles/alert';
@import './defaultStyles/navbar';

* {
    box-sizing: border-box;
}

html,
body {
    color: var(--font_color);
    font-family: var(--font);
    font-weight: var(--font_weight);
    font-size: var(--font_size);
    width: 100%;

    input:focus {
        box-shadow: none !important;
        border-color: var(--primary) !important;
    }

    textarea::placeholder {
        font-family: 'TT Rounds Condensed';
        font-style: normal;
        font-weight: 800;
    }
}

a,
a:visited {
    color: var(--link_color);
}

sup {
    font-size: 75%;
    vertical-align: super;
}

.container {
    margin: 60px auto 0;
    max-width: 360px;
}

.emex_green {
    color: $emex_green !important;
}

.emex_red {
    color: $emex_red !important;
}
