@import '../../settings.scss';

.sessionStatus {
    margin: auto;

    span {
        display: inline-block;
        vertical-align: middle;
        border-radius: var(--border_radius_narrow);
        margin: 0 10px 0 0;
    }

    svg {
        max-height: 20px;
        height: 20px;
    }

    &--init {
        span:nth-child(2) {
            opacity: 1;
        }
    }
    &--connected {
        svg {
            fill: var(--connected);
        }

        span:nth-child(2) {
            opacity: 1;
        }
    }
    &--disconnected {
        svg {
            fill: var(--aborted);
        }

        span:nth-child(2) {
            animation: blink-animation-session 1s steps(1, start) infinite;
        }
    }
}

@keyframes blink-animation-session {
    50% {
        opacity: 0.3;
    }
}
