@import '../../settings.scss';

$screenMax: 600px;

.notificationDisplay {
    position: fixed;
    z-index: 99;

    top: 10px;
    left: 50%;

    width: 90%;
    @media (min-width: $screenMax) {
        width: 50%;
    }
    padding: 10px;

    transform: translate(-50%, calc(-100% - 30px));

    transition: 0.2s;

    &--active {
        transform: translate(-50%, 0);
    }

    &__wrapper {
        position: relative;
        transition: all 0.3s;

        &.notificationDisplay__wrapper--inactive {
            height: 0;
            padding: 0;
            border: 0;
            overflow: hidden;
        }
    }
    &__wrapper + &__wrapper {
        margin-top: 10px;
    }

    &__messages {
        @media (min-width: $screenMax) {
            max-width: calc(100% - 30px);
        }

        & div {
            margin-bottom: 10px;
        }

        .flex__container {
            @media (min-width: $screenMax) {
                display: flex;
            }

            p + p {
                margin: 15px 0 0;
            }

            span {
                margin-right: 10px;
                display: block;
                min-width: 150px;
            }
        }
    }

    &__close {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;

        &::after {
            content: '+';
            display: block;
            font-size: 60px;
            line-height: 30px;
            transform: rotate(45deg);
        }
    }

    // Custom warning notification
    .alert--warning {
        color: #004085;
        background-color: #cce5ff;
        border-color: #b8daff;

        .notificationDisplay {
            &__messages {
                p {
                    @media (min-width: $screenMax) {
                        display: flex;
                        justify-content: center;
                    }
                }

                button,
                .btn,
                .btn--primary {
                    text-decoration: none;
                    text-align: center;
                    position: relative;
                    background: #004085;
                    color: #cce5ff;
                    border: 2px solid #004085;
                    &:hover {
                        background-color: #cce5ff;
                        color: #004085;
                    }
                }

                .btn--ok {
                    right: -50%;
                    translate: -50% 0;
                }

                .button__container {
                    display: flex;
                    justify-content: center;

                    .btn {
                        margin: 0 10px;
                    }
                }
            }
        }
    }

    .alert--pending {
        .notificationDisplay {
            &__pending {
                display: flex;
                justify-content: center;
            }

            &__messages {
                p {
                    @media (min-width: $screenMax) {
                        display: flex;
                        justify-content: center;
                    }
                }
                .send-password-reset__button {
                    position: absolute;
                    left: 50%;
                    translate: -50% -10px;
                    bottom: 0;
                    z-index: 1;
                }
            }
        }
    }

    .alert--error {
        .notificationDisplay {
            &__messages {
                .btn--danger {
                    position: relative;
                    right: -50%;
                    translate: -50% 0;
                    background: #721c24;
                    color: #f8d7da;
                    border: 2px solid #721c24;
                    &:hover {
                        background-color: #f8d7da;
                        color: #721c24;
                    }
                }
            }
        }
    }

    .alert--declined {
        .notificationDisplay {
            &__pending {
                display: flex;
                justify-content: center;
            }

            &__messages {
                p {
                    @media (min-width: $screenMax) {
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
