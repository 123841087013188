.sms-guide {
    display: none;
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.3);
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 99;

    &--shown {
        display: flex;
    }

    &__content {
        position: relative;
        padding: 20px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: center;
        border-radius: var(--border-radius);
        min-height: 600px;
        max-height: 80vh;
        max-width: 540px;
        background: var(--background_color);
        overflow: auto;
    }

    &__close {
        cursor: pointer;
        align-self: flex-end;
        display: flex;
        top: 0;
        align-items: center;
        justify-content: center;

        margin-bottom: 15px;
        color: var(--primary);
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }
    }

    p,
    h3 {
        text-align: center;
        line-height: 1.5em;
        margin-bottom: 10px;
    }

    h3 {
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--primary);
        font-weight: bold;
    }

    h3 + p,
    p + p {
        margin-top: 10px;
    }
    p {
        span {
            color: red;
        }
    }

    a {
        text-decoration: none;
    }

    .lds-ring div {
        border-color: var(--secondary) transparent transparent transparent;
    }
}
