.send-password-button {
    &__text-wrapper {
        display: flex;
        align-items: center;
    }

    &__isHidden {
        visibility: hidden;
    }

    .btn {
        position: relative;
        display: flex;
        align-items: center;
    }

    .btn:hover {
        svg {
            stroke: var(--primary);
        }
    }

    a {
        cursor: pointer;
    }
}