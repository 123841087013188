@import '../../settings.scss';

.dropdown {
    position: relative;
    text-align: left;

    &--disabled {
        color: #ffffff;

        .dropdown__label {
            background-color: $grey;
            cursor: default;

            svg {
                fill: #fff;
            }
        }
    }

    &__label {
        background: var(--dropdown_background);
        padding: var(--dropdown_padding);
        line-height: 1.5;

        border-radius: var(--border_radius_super_narrow);
        position: relative;

        cursor: pointer;

        span {
            display: inline-block;
            margin-right: 35px;
        }

        .dropdown__toggleIcon {
            width: 30px;
            height: 30px;

            position: absolute;
            right: 15px;
            top: 50%;
            transform: translate(0, -50%);
        }

        .dropdown--open & {
            border-radius: var(--border_radius_super_narrow) var(--border_radius_super_narrow) 0 0;
        }

        .dropdown--open.dropdown--top & {
            border-radius: 0 0 var(--border_radius_super_narrow) var(--border_radius_super_narrow);
        }

        .lds-ring {
            position: absolute;
            right: 50px;
            bottom: 50%;
            translate: 0 50%;
        }
    }

    &__content {
        position: absolute;
        overflow: hidden;
        background: white;
        z-index: 99;

        height: 0;
        width: 100%;

        .dropdown--open & {
            height: auto;
            overflow: auto;
            border: 2px solid var(--dropdown_background);
        }

        .dropdown--top & {
            bottom: 100%;
        }

        & > div {
            display: block;

            background: white;

            & > div {
                padding: var(--dropdown_padding);
                line-height: 1.5;
                border-bottom: 2px solid var(--dropdown_background);
                cursor: pointer;
                background: white;

                transition: background-color 0.2s;

                &:last-child {
                    border: none;
                }

                &:hover {
                    background: var(--dropdown_background);
                }
            }
        }
    }

    &--flags {
        margin-right: 10px;
        .dropdown__content {
            text-align: center;
        }

        .dropdown__label {
            background: #ffffff;
            padding: 9px 10px;

            span {
                display: flex;
                margin-right: 30px;
            }
        }

        svg {
            width: 30px;
            height: 20px;
            border-radius: var(--border_radius_super_narrow);
        }

        .dropdown__toggleIcon {
            fill: $grey_dark;
            width: 20px;
            height: 20px;
        }

        .dropdown__content {
            border-color: #ffffff;

            & > div {
                & > div {
                    padding: 8px;
                    line-height: 1;
                    border-color: #ffffff;
                }
            }
        }
    }
}
