.dispatcherNavbar {
    &__connected {
        display: flex;

        & > div {
            margin: 5px 0;
        }
    }

    .logo {
        span {
            display: none;
        }
    }

    .connectionInfoWrapper {
        margin-left: 15px;
        display: grid;
        grid-template-rows: 1fr 1fr;
    }

    .dev-caller-link {
        width: 32px;
        position: absolute;
        transform: translate(-50%, -50%);
        cursor: pointer;
    }

    .dev-invited-users-link {
        width: 32px;
        position: absolute;
        transform: translate(-50%, -50%);
        cursor: pointer;
        fill: blue;
    }

    &--device-transfer-status {
        display: flex;
        align-items: center;
        opacity: 0.3;
    }
}
