.navbar {
    padding: 5px 20px;
    border-bottom: 6px solid var(--header_footer_seperator);
    background-color: var(--element_background_color);

    position: relative;
    min-height: 80px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    z-index: 4;

    & > div:last-child {
        display: flex;
        align-items: center;
    }

    .logo {
        position: relative;
        height: 70px;
        width: 200px;
        display: flex;
        align-items: center;

        img {
            width: 100%;
            height: 100%;
            max-width: var(--logo_max_width);
            margin-right: 20px;
            object-fit: contain;
            object-position: left;
        }

        span {
            font-weight: 700;
            font-size: $font_large;
            vertical-align: bottom;
            color: var(--primary);
            white-space: nowrap;

            .secondary {
                color: var(--secondary);
            }
        }
    }
}
