div {
    button {
        position: relative;
        z-index: 2;
    }

    .btn--isHidden {
        opacity: 0;
        pointer-events: none;
    }

    .copy-link {
        position: absolute;
        translate: 0 -90%;
        transition: opacity 0.3s;
        z-index: 1;
    }

    .copy-link--isHidden {
        opacity: 0;
    }
}
