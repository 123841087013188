.videoContainer {
    .dispatcherDashboard & {
        min-height: 483px;
    }

    video {
        max-width: 100%;

        .dispatcherDashboard & {
            max-height: 480px;
            min-height: 480px;
        }
    }

    .videoContainer__inner-wrapper {
        position: relative;

        .lds-ring {
            position: absolute;
            right: 50%;
            top: 50%;
            translate: 50% -50%;
        }

        .dispatcherDashboard & {
            position: relative;
            min-height: 330px;
            display: flex;
            align-items: flex-end;
        }
    }

    &--isHidden {
        display: none;
    }
}

@keyframes blink-animation-video {
    50% {
        opacity: 1;
    }
}
