.resend-sms {
    &__text-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .lds-ring{
            margin: 0;
        }
    }

    &__isHidden {
        visibility: hidden;
    }

    .btn {
        position: relative;
        display: flex;
        align-items: center;
    }

    .btn:hover {
        svg {
            stroke: var(--primary);
        }
    }

    a {
        cursor: pointer;
    }

    .btn--danger{
        position: relative;
        right: -50%;
        translate: -50% 0;
        background: #721c24;
        color: #f8d7da;
        border: none;
    }
}
