@import '../../settings.scss';

.phone-country {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    margin-top: 2px;
    left: -31px;

    span:first-child {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $grey_darker;
        border-radius: 3px;
        position: relative;
        left: -10px;
    }

    span {
        display: inline-block;
        margin-right: 5px;

        img {
            border-radius: 2px;
        }
    }

    span + span {
        color: $grey_darker;
    }

    img {
        width: 24px;
        height: auto;
    }
}

.startDashboard {
    .phone-country {
        white-space: nowrap;
        left: 0;
        margin-left: 10px;

        span:first-child {
            left: 0;
        }
    }       
}
