@import '../../settings.scss';

.languageSelect {
    .dropdown__label {
        .languageSelectText {
            display: none;
        }
    }

    div.dropdown__content {
        z-index: 999;
        
        div {
            border-color: var(--dropdown_background);
        }
    }

    &__option {
        display: flex;
        border-bottom: var(--dropdown_background) solid 2px;

        :nth-child(1) {
            span {
                display: none;
            }
        }

        :nth-child(2) {
            svg {
                fill: var(--primary);
            }
        }
    }

    .dropdown--open {
        .dropdown__content {
            border: var(--dropdown_background) solid 2px;

            svg {
                border-radius: 5px;
            }
        }
    }
}
