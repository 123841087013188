.cameraChooser {
    position: relative;

    &__hint {
        padding: 5px;
        margin-top: 10px;
        border-radius: var(--border_radius);
        background: var(--hint);
        line-height: 1.5;
    }

    &__element {
        display: flex;
        justify-content: space-between;
        align-items: center;

        svg {
            fill: var(--primary);
        }
    }
}
