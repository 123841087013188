@import '../../settings.scss';

.systemMessages {
  &__messages {
    max-width: 800px;
    margin: 0 auto;

    & > div {
      padding: 15px;
      border-radius: var(--border_radius);
      margin: 15px;
    }

    h2 {
      font-size: $font_medium;
      font-weight: 700;
      margin-bottom: 10px;
    }

    p + p {
      margin-top: 6px;
    }

    b, strong {
      font-weight: bold;
    }

    i, em {
      font-style: italic;
    }

    ul {
      list-style: disc inside;
    }

    ol {
      list-style: decimal inside;
    }
  }
}