.videoContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .dispatcherDashboard & {
        min-height: 483px;
    }

    video {
        .dispatcherDashboard & {
            max-height: 480px;
            min-height: 480px;
        }
    }

    .videoContainer__inner-wrapper {
        position: relative;

        .dispatcherDashboard & {
            position: relative;
            min-height: 330px;
            display: flex;
            align-items: flex-end;
            // max-width: 80%;
        }
    }

    #videoContainer__inner {
        display: none;
        position: relative;
        height: 100%;
    }

    .pointerElement {
        position: absolute;
        width: 60px;
        top: 0;
        left: 0;
        display: none;
        transform: translate(-50%, -50%);
        opacity: 0;

        svg {
            fill: var(--pointer);
        }
    }

    &__placeholder {
        width: 100%;
        height: 100%;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            max-width: 200px;
            max-height: 200px;
            width: 70%;
            height: 70%;
        }
    }

    &.videoContainer--active {
        #videoContainer__inner {
            display: block;
        }

        .videoContainer__placeholder {
            display: none;
        }
    }

    &.videoContainer--pointer {
        #videoContainer__inner {
            cursor: pointer;
        }
    }

    &.videoContainer--pointerVisible {
        .pointerElement {
            display: block;
            animation: blink-animation-video 1s steps(1, start) infinite;
        }
    }

    &.videoContainer--isRecordingActive {
        svg {
            display: none;
            position: absolute;
            fill: var(--primary);
            height: 20px;
            width: 50px;
            top: 5px;
            right: 5px;
            z-index: 1;
        }

        #innerCircle {
            animation: pulse 2s linear infinite;
            transform-origin: 5.81px 6px;
        }

        @media (orientation: landscape) {
            svg {
                display: block;
                right: 0;
            }
        }
    }

    &--isHidden {
        display: none;
    }
}

@keyframes blink-animation-video {
    50% {
        opacity: 1;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.6);
    }
    100% {
        transform: scale(1);
    }
}