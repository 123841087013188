@import '../../settings.scss';

.systemFooter {
    position: sticky;
    bottom: 0;
    border-top: 1px solid var(--primary);
    background-color: var(--element_background_color);
    font-size: 0.85rem;
    z-index: 10;

    & > div {
        max-width: 800px;
        margin: 0 auto;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__state {
        display: flex;
        align-items: center;
    }

    &__status {
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-left: 10px;
        vertical-align: middle;

        border-radius: var(--border_radius);

        &--green {
            background: $green;
        }
        &--yellow {
            background: $yellow;
        }
        &--red {
            background: $red;
        }
    }

    &__version {
        align-self: center;
    }

    &__help {
        a {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        svg {
            width: 30px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 0;
            fill: var(--external_icon_fill);
        }
    }

    &__reload {
        position: fixed;

        top: -100px;
        left: 50%;

        width: 800px;
        transform: translateX(-50%);

        transition: top 0.5s;
        display: flex;
        align-items: center;
    }

    &--reload {
        .systemFooter__reload {
            top: 0;
        }
    }

   .footer__logo {
       left: 20px;
       max-width: 110px;
       position: absolute;
       max-height: 40px;
   }
}
