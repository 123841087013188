@import '../../settings.scss';

.gridContainer.infoBox main {
    padding: 0;
}

.informationPanel {
    position: relative;
    &__item {
        padding: 15px 10px 15px 5px;
        text-align: right;
        margin-left: 2px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        font-weight: 700;
        color: var(--panel_highlight);

        svg {
            position: absolute;
            width: 30px;
            transform: translate(5px, -15px);
            fill: var(--external_icon_fill);
        }
        .itemWrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        button {
            margin: 0 10px 10px;
        }
    }

    .alert-panel {
        .link-copied {
            position: absolute;
            opacity: 1;
            left: 5px;
            bottom: 0;
            translate: 0 -25%;
            width: 50%;
            text-align: center;
            transition: opacity 0.3s;
            z-index: 2;

            &--isHidden {
                opacity: 0;
                z-index: 1;
            }
        }
    }

    span {
        text-align: left;
        color: var(--font_color);
    }

    .icon {
        display: inline-block;
        height: 20px;
        width: auto;
        margin-right: 10px;
    }

    &--is-highlighted {
        box-sizing:unset !important;
        animation: border-flash 1.2s linear infinite;
    }
}

@keyframes border-flash {
    0% {
        box-shadow: inset 0 0 0 8px rgba(120, 120, 120, 0.5);
    }
    100% {
        box-shadow: inset 0 0 0 4px rgba(120, 120, 120, 0);
    }
}