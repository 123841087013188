@import '../../settings';

.chatView {
    background: white;
    position: relative;

    &__languageSelect {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        padding-bottom: 10px;
        border-bottom: 3px dotted #dfdfdf;

        .dropdown {
            float: right;
            width: 150px;
        }
    }

    &__language {
        padding: 10px 5px;

        span {
            color: var(--primary);
        }
    }

    &__users {
        display: flex;
        justify-content: space-between;

        font-size: $font_smaller;

        & > div {
            padding: 2px 20px;
            border-radius: var(--border_radius_narrow);

            &:first-child {
                background: var(--chat_background_left);
            }
            &:last-child {
                background: var(--chat_background_right);
            }
        }
    }

    &__noChat {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
        width: 70%;
        height: 70%;

        max-width: 200px;
        max-height: 200px;
    }

    &__noChat + &__messages-wrapper {
        opacity: 0.1;
    }

    &__messages-wrapper {
        border-radius: 2px;
        margin: 5px 0;
        position: relative;
        border: 1px solid #efefef;
        overflow: hidden;
        max-height: 100%;

        &::before,
        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 20px;
            position: absolute;
            z-index: 5;
            border-radius: 2px;
        }

        &::before {
            background: linear-gradient(to bottom, rgba(240, 240, 240, 1) 0%, rgba(255, 255, 255, 0) 100%);
        }

        &::after {
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
            bottom: 0;
        }
    }

    &__messages {
        min-height: 200px;
        overflow: auto;
        padding: 5px 5px 10px 5px;

        & > div {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            min-height: 100%;
            padding-top: 50%;
        }

        .dispatcherDashboard & {
            height: calc(100vh - 540px);
        }
    }

    &__blocks {
        margin-top: 10px;
    }

    &__form {
        margin-top: 10px;
        @include clearfix();
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        &--is-file-preview-mode-active {
            .chatViewInputWrapper {
                position: relative;

                .chat-file-preview {
                    display: flex;
                    position: absolute;
                    height: 180px;
                    width: 100%;
                    bottom: 0;
                    background-color: #ffffff;
                    border: 1px solid $grey;
                    border-radius: var(--border_radius_narrow);
                    box-shadow: 0px 2px 4px 2px #b9b9b9;
                    z-index: 5;

                    svg {
                        cursor: pointer;
                    }

                    .chat-file-icons {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 2px solid $grey;
                        border-radius: var(--border_radius_narrow);
                        margin: 15px;
                        padding: 5px;
                        pointer-events: none;
                        cursor: pointer;
                    }

                    svg:nth-child(1) {
                        position: relative;
                        background-color: var(--primary);
                        max-width: 100px;
                        border-radius: 50px;
                        height: 80px;
                        padding: 8px;
                        fill: #ffffff;
                    }

                    svg:nth-child(2) {
                        position: absolute;
                        top: 5px;
                        translate: 45px 0;
                        pointer-events: none;
                        cursor: pointer;
                        pointer-events: all;
                    }

                    .chat-file-info {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        height: 100px;
                        min-width: 50px;
                        margin: 10px;
                    }

                    .chat-file-info > span {
                        font-size: 0.85rem;
                        line-height: 1.25;
                    }

                    span:nth-child(3) {
                        color: #b9b9b9;
                        line-height: 1.5;
                    }
                }
            }

            .chatView__input {
                width: 100%;
                z-index: 5;

                input[type='text'] {
                    margin: 0 0 5px 5px;
                    border: none;
                }
            }

            .chatView__input::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: $grey;
            }

            .chatView__submit {
                width: 32px;
                height: 32px;
                margin: 5px 10px;
                z-index: 5;

                svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    translate: -50% -50%;
                }

                svg:hover {
                    path {
                        fill: var(--primary);
                    }
                }
            }
        }
    }

    &--is-caller-file-preview-mode-active {
        .chat-file-preview {
            display: flex;
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            background-color: #ffffff;
            z-index: 5;

            .chat-file-icons {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100px;
                top: 50%;
                left: 50%;
                translate: -50% -50%;
                border: 2px solid $grey;
                border-radius: var(--border_radius_narrow);
                margin: 15px;
                padding: 15px;
                pointer-events: none;
                cursor: pointer;

                svg {
                    min-width: 80px;
                    max-width: 100px;
                }
            }

            svg {
                cursor: pointer;
            }

            svg:nth-child(1) {
                background-color: var(--primary);
                border-radius: var(--border_radius_narrow);
                border-radius: 50px;
                fill: #ffffff;
                padding: 8px;
            }

            svg:nth-child(2) {
                top: 0;
                right: 0;
                position: absolute;
                translate: 50% -50%;
                cursor: pointer;
                pointer-events: all;
            }

            span:nth-child(3) {
                color: #b9b9b9;
                line-height: 1.5;
            }

            .chat-file-info {
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;
                top: 10px;
                width: 100%;
            }

            .chat-file-info > span {
                font-size: 0.85rem;
                line-height: 1.25;
            }

            .chat-file-info::after {
                content: '';
                margin-top: 10px;
                width: 100%;
                height: 1px;
                background-color: #b9b9b9;
            }
        }

        .chatView__form {
            .chatViewInputWrapper {
                .chatView__input {
                    width: 100%;
                    z-index: 5;

                    input[type='text'] {
                        margin: 0 0 5px 5px;
                        border: none;
                    }
                }

                .chatView__input::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: $grey;
                }

                .chatView__submit {
                    position: relative;
                    width: 32px;
                    height: 32px;
                    margin: 5px 10px;
                    z-index: 5;

                    svg {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        translate: -50% -50%;
                    }
                }
            }
        }
    }

    &__label {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin: 10px 0 0;
        flex-basis: 100%;
        font-size: $font_smaller;

        &--hidden {
            display: none;
        }

        label {
            margin: 0;
        }

        span {
            max-width: 100%;
            text-align: left;
            font-weight: 400;
        }
    }

    &__input {
        display: flex;
        width: 70%;
        margin-right: 3px;
        z-index: 2;
    }

    &__submit {
        width: 30%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__qrCode {
        display: flex;
        align-items: center;
        font-size: small;

        button {
            height: 35px;
            margin: 10px 5px 5px;
            padding: 0px 12px;
            
            svg {
                height: 30px;
                fill: #000000;
            }
        }

        span {
            font-weight: 400;
        }

    }

    .chatViewInputWrapper {
        display: flex;
        width: 100%;
        align-content: space-between;
    }

    .loading {
        padding: 5px;
        margin-top: 10px;
        border-radius: var(--border_radius);
        background: var(--hint);
    }
}

.chat__wrapper--media-dock-active {
    .chatView {
        .chatView__messages {
            min-height: 200px;

            & > div {
                min-height: 100%;
            }
        }
    }
}

@media (orientation: portrait) and (max-height: 850px) {
    .chatView {
        &__language {
            font-size: 0.8rem;
            padding: 5px;
            text-align: center;
        }

        &__users {
            margin-bottom: 5px;
        }

        &__label--hidden {
            display: none;
        }

        &__form {
            margin-top: 5px;
        }
    }
}

@media (orientation: landscape) and (max-height: 500px) {
    .chatView {
        &__language {
            margin: auto;
            font-size: 0.8rem;
            padding: 3px;
            max-width: 160px;
            text-align: center;
        }

        &__label--hidden {
            display: none;
        }

        &__form {
            margin-top: 5px;
        }
    }
}
