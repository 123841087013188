@import '../../settings.scss';

.media {
    min-height: 250px;

    .mediaPlaceholder {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        box-sizing: border-box;

        svg {
            max-width: 200px;
            max-height: 200px;
            width: 100%;
            height: 100%;
        }

        &--hidden {
            #noMedia {
                display: none;
            }
        }
    }

    .info-container {
        position: relative;
        width: calc(25% - 10px);
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
    }

    .media-container {
        position: relative;
        margin: 0 5px 5px 5px;
        width: 90%;
        aspect-ratio: 4 / 3;
        background-color: #000;
        overflow: hidden;

        .file-type-icon {
            svg {
                top: 5px;
                left: 5px;
                width: 15%;
                overflow: visible;
                height: auto;
                background-color: #00000000;
            }
        }

        .file-icon {
            svg {
                fill: #fff;
            }
        }
    }

    &__gallery {
        position: relative;
        height: 100%;
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;

        .image-info {
            margin-bottom: 5px;
            cursor: pointer;

            img {
                position: relative;
                width: auto;
                height: auto;
                max-height: 100%;
                max-width: 100%;
                padding: 1px;
                top: 50%;
                left: 50%;
                translate: -50% -50%;
            }
        }

        .recording-info {
            margin-bottom: 5px;
            button {
                position: relative;
                max-height: 100%;
                width: 100%;
                border-radius: 0;
                padding: 0;
                border: none;

                .thumbnail-video {
                    img {
                        svg {
                            position: absolute;
                            width: auto;
                            border-radius: 0;
                            background-color: #00000000;
                            padding: 1px;
                            top: 50%;
                            left: 50%;
                            translate: -50% -50%;
                        }
                    }
                }

                .thumbnail-audio {
                    position: relative;
                    img {
                        position: relative;
                        left: 50%;
                        translate: -50% 0;
                    }
                }
            }

            span {
                width: unset;
            }

        }

        .file-info {
            position: relative;
            width: calc(25% - 10px);
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 5px;
            cursor: pointer;

            svg {
                &:nth-child(1) {
                    position: absolute;
                    width: auto;
                    border-radius: 0;
                    background-color: #00000000;
                    padding: 1px;
                    top: 50%;
                    left: 50%;
                    translate: -50% -50%;
                }
            }

            span {
                align-self: center;
                text-overflow: ellipsis;
            }
        }

        img {
            width: 100%;
            height: 100%;
        }

        svg {
            position: absolute;
            width: 30px;
            bottom: 30px;
            right: 15px;
            z-index: 3;
            fill: var(--primary);
            background: white;
            padding: 3px;
            border-radius: var(--border_radius);
        }

        span {
            display: inline-block;
            margin: 5px 0 0;
            text-align: center;
            width: 90%;
            color: var(--font_color);
            text-decoration: none;
            font-size: 0.85em;
        }
    }

    &__hint {
        margin-top: 10px;
        small {
            font-size: 12px;
        }
    }
}
