.startDashboard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    background-color: var(--background_color);

    .Login {
        position: relative;
        width: inherit;
        height: inherit;
        flex-grow: 1;

        .flex {
            min-height: 20px;
        }

        form {
            position: relative;
        }
    }

    .navbar {
        .logo {
            span {
                display: none;
            }
        }
    }

    .phonebook {
        position: absolute;
        top: 25px;
        left: 100%;
        margin-left: 10px;
    }
}
