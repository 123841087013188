.file-input {
    input {
        position: fixed;
        top: -10000px;
        left: -10000px;
    }

    svg {
        width: 40px;
        height: 40px;
        cursor: pointer;
        fill: var(--primary);
    }

    &--isNotAllowed {
        svg {
            pointer-events: none;
            fill: #dbdbdb;
        }
    }

    .loading.loading {
        position: relative;
        background: none;
        width: 40px;
        padding: 0;
        margin: 0;
        top: 50%;
        translate: 0 -50%;
    }
}
