button,
.btn {
    display: inline-block;
    font-weight: 700;
    color: var(--font_color);

    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;

    border: var(--border_width) var(--border_style) black;
    padding: var(--input_padding);
    font-size: $font_small;
    line-height: 1.5;
    border-radius: var(--border_radius);
    font-family: var(--font);

    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;

    svg {
        transition: fill 0.15s ease-in-out;
    }

    &--narrow {
        border-radius: var(--border_radius_narrow);
        line-height: 1;
        padding: var(--narrow_padding);
        font-weight: 700;
    }

    &--primary {
        color: var(--text_on_primary);
        background-color: var(--primary);
        border: var(--border_width) var(--border_style) var(--primary);

        svg {
            fill: var(--text_on_primary);
        }

        &:hover {
            background-color: white;
            color: var(--primary);

            svg {
                fill: var(--primary);
            }
        }
    }

    &--secondary {
        color: var(--text_on_secondary);
        background-color: var(--secondary);
        border: var(--border_width) var(--border_style) var(--secondary);

        &:hover {
            background-color: white;
            color: var(--secondary);
        }
    }

    &--accept {
        min-width: 80px;
        color: var(--text_on_primary_caller);
        background-color: var(--active);
        border: var(--border_width) var(--border_style) var(--active);
    }

    &--decline {
        min-width: 80px;
        color: var(--text_on_primary_caller);
        background-color: var(--inactive);
        border: var(--border_width) var(--border_style) var(--inactive);
    }

    &--full {
        width: 100%;
    }
    &--icon {
        display: flex;
        align-items: center;

        svg {
            fill: var(--text_on_primary);
            height: 24px;
            margin-right: 10px;
            transition: fill 0.3s;
        }

        &:hover {
            svg {
                fill: var(--primary);
            }
        }
    }

    &:focus {
        outline: none;
    }

    &:disabled {
        background: $grey;
        border-color: $grey;
        cursor: not-allowed;

        &:hover {
            color: white;

            svg {
                fill: white;
            }
        }
    }
}
