.timeoutOverlay {
    display: none;
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.3);
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 99;

    &--shown {
        display: flex;
    }

    &__content {
        position: absolute;
        padding: 20px;
        background: white;
        width: 400px;
        left: 50%;
        top: 50%;
        border-radius: var(--border_radius);

        transform: translate(-50%, -50%);

        p {
            line-height: 1.3em;
        }

        span:nth-child(1) {
            display: block;
            white-space: pre-line;
            font-size: x-large;
            line-height: 1.5em;
            text-align: center;
        }

        span:nth-child(2) {
            display: block;
            line-height: 1.3em;
        }

        span:nth-child(3) {
            display: flex;
            line-height: 1.3em;
            justify-content: center;
        }
    }

    &__buttons {
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }

    .timeoutOverlay__hiddenButton {
        display: none;
    }
}
