.openStreetMap {
    position: relative;

    .map {
        display: none;
    }

    .map,
    .noMap {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    .noMap {
        svg {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 200px;
            height: 200px;
            transform: translate(-50%, -50%);
        }
    }

    &--active {
        .map {
            display: block;
        }
        .noMap {
            display: none;
        }
    }

    &__wrapper {
        position: relative;
        padding-top: 56.5%;
    }

    .mouseEventsDisabled {
        pointer-events: none;

        button {
            pointer-events: none;
        }
    }

    .mapboxgl-ctrl-center {
        width: auto;
        padding: 0 10px;
    }

    .mapboxgl-ctrl-zoom-in,
    .mapboxgl-ctrl-zoom-out {
        border-radius: 0;
    }
}
