.hls-stream-container {
    &__loading-container {
        height: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
    }

    video {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &--is-manifest-available {
        background-color: unset;
        video {
            display: block;
        }
    }

    &--is-manifest-loading {
        background-color: #dbdbdb;
        opacity: 0.5;
    }
}
