@import "../../settings.scss";

.webRTCSwitcher {
    position: relative;

    #toggleConferencing {
        &--caller-page-loaded-once {
            z-index: 5;
        }
    }

    .toggle__container {
        div > svg {
            position: absolute;
            bottom: 0;
            translate: 0 -25%;
            right: 60px;
            z-index: 10;
            cursor: pointer;
        }

        div > svg:hover {
            transform: scale(1.25);
        }

        .recording-timer {
            position: relative;
            z-index: 10;
            
            span {
                position: absolute;
                translate: -60px 11px;
                right: 0;
                top: 0;
                cursor: pointer;
            }
        }
    }
}
