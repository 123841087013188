.paint-toolbarBG {
    position: absolute;
    width: 34px;
    background: #dbdbdb;
    height: 330px;
    bottom: 2px;
    right: -40px;
    border-radius: var(--border_radius_super_narrow);
}

.paint-toolbar {
    width: 54px;
    right: -60px;
    position: absolute;
    bottom: 2px;

    max-height: 100%;

    display: flex;
    flex-direction: column;
    z-index: 1;

    &::before {
        display: block;
        content: '';
        position: absolute;
        height: calc(100% + 10px);
        width: 100%;
        bottom: 0;
        left: 0;
        background-color: white;
        opacity: 0.7;
    }

    &--is-hidden {
        display: none;
    }

    &--screenshot-dialogue-shown,
    &--isPaintingAllowed {
        &::before {
            display: none;
        }
    }

    &__colors,
    &__shapes,
    &__tools {
        display: flex;
        flex-direction: column;
    }

    &__colors + &__shapes,
    &__shapes + &__tools {
        margin-top: 10px;
    }

    &__colors {
        button.is-active {
            border: 1px solid var(--primary) !important;
            width: 24px;
            height: 24px;
            cursor: default;
        }
    }

    &__shapes {
        button:not(.btn) {
            background-color: var(--text_on_primary);
            svg {
                fill: var(--primary);
            }
        }

        button.is-active {
            background-color: var(--primary);
            cursor: default;
            svg {
                fill: var(--text_on_primary);
            }
        }
    }

    &__tools {
        button:not(.btn) {
            background-color: var(--text_on_primary);
            svg {
                fill: var(--primary);
            }
        }
    }

    button:not(.btn) {
        margin: 5px;
        width: 24px;
        height: 24px;
        border-radius: var(--border_radius_super_narrow);
        border: 0px solid transparent;
        padding: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            transition: all 0.3s;
            width: 16px;
            height: 16px;
        }
    }

    &__color1 {
        background: var(--COLOR1);
    }

    &__color2 {
        background: var(--COLOR2);
    }

    &__color3 {
        background: var(--COLOR3);
    }

    &__white {
        background: var(--WHITE);
    }

    &__black {
        background: var(--BLACK);
    }

    &__screenshot {
        visibility: hidden;

        &--is-visible {
            visibility: visible;
        }
    }
}
