.invitation-sent-list {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;

    .list-item {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 450px;
        height: 50px;
        margin: 0 0 0 30px;

        & > label {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 40px;
            border: 3px dotted #dfdfdf;
            width: 350px;
            text-indent: 10px;
            color: #a6a6a6;

            & > svg {
                margin-right: 5px;
                border: 1px solid #dfdfdf;
                border-radius: 15px;
                fill: #dfdfdf;
            }
        }

        & > button {
            height: 30px;
            width: 30px;

            & > svg {
                position: absolute;
                right: 50%;
                top: 50%;
                translate: 50% -50%;
            }
        }

        & > .loading-container {
            display: flex;
            justify-content: center;
            width: 50px;
            translate: 0 0;
        }

        &--has-error {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 50px;
            margin: 0 0 0 10px;

            & > label {
                display: flex;
                align-items: center;
                height: 40px;
                border: 3px dotted red;
                flex-basis: 90%;
                text-indent: 10px;
                color: #a6a6a6;
            }

            span {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-basis: 10%;
                color: red;
                font-size: large;
                font-weight: 800;
            }

            & div > svg {
                position: absolute;
                width: 30px;
                height: 30px;
            }
            & > button {
                height: 30px;
                width: 30px;

                svg {
                    position: absolute;
                    right: 50%;
                    top: 50%;
                    translate: 50% -50%;
                }
            }
            & input {
                border-color: var(--secondary);
                width: 355px;
                height: 40px;
            }

            .list-item--warning {
                position: relative;
                svg {
                    position: absolute;
                    bottom: 50%;
                    translate: -85px 50%;
                }
            }

            .list-item--is-in-edit-mode {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    position: relative;
                    margin-right: 10px;
                    cursor: pointer;
                }

                &:hover {
                    animation-delay: 0s;
                    animation-duration: 0.2s;
                    animation-name: grow;
                    animation-fill-mode: forwards;
                }
            }
        }
    }
}
