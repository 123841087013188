.chatLanguages {
    .contentWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-height: 40px;
    }

    span {
        svg {
            fill: var(--primary);
        }
    }
}
