// COLORS
$grey_light: rgb(245, 245, 245);
$grey: #dbdbdb;
$grey_darker: #999999;
$grey_dark: rgb(120, 120, 120);

$green: #3AC13A;
$yellow: #FFE95E;
$red: #DB1C1C;

$emex_green: rgb(0, 168, 165);
$emex_red: #d25425;

// FONTS
$font_smallest: 0.75rem;
$font_smaller: 0.85rem;
$font_small: 1rem;
$font_medium: 1.25rem;
$font_large: 1.625rem;

// defaults, get overWritten by customer specific values (see -> helper)
:root {
    --background_color: white;
    --element_background_color: white;
    --logo_max_width: 100%;

    --header_footer_seperator: lime;
    --panel_highlight: lime;
    --chat_background_right: hotpink;
    --chat_background_left: hotpink;

    --active: green;
    --inactive: red;
    --text_on_active: white;
    --text_on_inactive: white;

    --dropdown_background: #7a7a7a;

    --primary_light: #d3d3d3;
    --primary: #7a7a7a;
    --text_on_primary: black;

    --secondary: #000000;
    --text_on_secondary: white;

    --connecting: grey;
    --connected: green;
    --paused: yellow;
    --aborted: red;

    --red: red;
    --green: green;

    --font_color: #010;
    --font_weight: 600;
    --link_color: #001;

    --font: 'Arial', 'Helvetica', sans-serif;
    --font_size: 16px;

    --border_radius: 0px;
    --border_radius_narrow: 2px;
    --border_width: 1px;
    --border_style: solid;

    --input_padding: 6px 12px;
    --dropdown_padding: 12px;
    --narrow_padding: 2px 20px;

    --gridbox_header_bg: rgb(120, 120, 120);
    --gridbox_header_text: white;
    --gridbox_header_weight: 700;
    --gridbox_padding: 8px;
    --gridbox_margins: 2px;

    --hint: #deecff;

    --pointer: red;

    --COLOR1: #fd0000;
    --COLOR2: #fffe06;
    --COLOR3: #18a7ff;
    --WHITE: white;
    --BLACK: black;
    --COLORC: #58fd51;
}

// UTILITIES
@mixin clearfix {
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}
