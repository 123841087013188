.infodiv {
    margin-left: 10px;
    max-width: 300px;
}

.primaryRow {
    flex-wrap: nowrap;
}

.container-full {
    margin-top: 10px;
}

.list-group-item-primary {
    background-color: #00a8a5;
    color: white;
}

.dispatcherDashboard {
    background-color: var(--background_color);

    &--isScreensharing {
        border: 5px solid var(--primary);
    }
}