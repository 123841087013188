.bidi-panel {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .dispatcherDashboard & {
        min-height: 150px;
        max-height: 150px;
    }

    &__placeholder {
        width: 100%;
        height: 100%;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            max-width: 150px;
            max-height: 150px;
            width: 70%;
            height: 70%;
        }
    }

    .bidi-panel__placeholder {
        display: relative;
    }

    &.bidi-panel--active {
        .bidiContainer__inner-wrapper {
            position: absolute;
            display: flex;
            width: 100%;
            top: 0;

            #bidiContainer__inner {
                width: 100%;

                #bidi-stream {
                    position: relative;
                    left: 50%;
                    transform: translateX(-50%);
                    max-height: 150px;
                    width: 100%;
                }
            }
        }

        .button__wrapper {
            position: absolute;
            right: 0px;
            top: 0px;
            
            span {
                display: flex;
                align-items: center;
                height: 36px;
    
                svg {
                    height: 55px;
                    cursor: pointer;
                }

            }
        }

        .blur-transition--isLoading {
            span {
                pointer-events: none !important;
                svg {
                    pointer-events: none !important;
                }
            }
        }

        .bidi-panel__placeholder {
            display: none;
        }
    }

    #bidi-stream::-webkit-media-controls-picture-in-picture-button {
        display: none !important;
    }

    #bidi-stream::-webkit-media-controls-overlay-play-button {
        display: none !important;
    }
}
