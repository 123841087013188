.geoSMS {
    display: none;
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.75);
    z-index: 100;
    padding: 40px;
    top: 0;
    left: 0;

    .flex {
        display: flex;
        justify-content: space-between;
        min-height: 20px;
    }

    &--open {
        display: block;
    }
    &--error {
        .geoSMS__errorMsg {
            display: block;
        }
    }

    &__wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 500px;
        transform: translate(-50%, -50%);

        background: white;
        padding: 20px;

        & > div {
            padding: 10px;

            span {
                display: inline-block;
                width: 100px;
                font-weight: bold;
            }
        }

        form > div {
            padding: 10px;
        }
    }

    &__close {
        padding: 10px;
        cursor: pointer;

        text-decoration: underline;
        color: var(--primary);
        text-align: right;
    }

    &__errorMsg {
        margin-top: 5px;
        display: none;
    }

    &__input-wrapper {
        position: relative;

        .phonebook {
            button {
                width: 80px;

                span {
                    display: none;
                }
            }
        }
    }

    .phonebook {
        position: absolute;

        top: 0;
        right: 0;
    }

    .phonebook + input {
        width: calc(100% - 70px);
    }
}
