.notes-panel {
    height: 200px;
    position: relative;

    textarea {
        height: 185px;
        width: 100%;
        margin-bottom: 10px;
        font-family: inherit;
        font-size: large;
        border: none;
        resize: none;
        overflow-y: auto;
        outline: none;
    }

    &__hint-seperator {
        width: 100%;
        height: 10px;
        position: relative;
        justify-content: right;
        display: flex;
        background: #ffffff;
        z-index: 10;

        span {
            display: flex;
            position: relative;
            opacity: 0.5;
            font-size: x-small;
        }
    }
}
