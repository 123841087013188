@import "../../settings.scss";

$height: 28px;
$width: 50px;
$padding: 6px;

.toggleSwitch {
    cursor: pointer;
    padding: 5px 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    span {
        display: inline-block;
        font-weight: 700;
        margin-right: 15px;
        transition: 0.2s;
    }

    input {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
    }

    &__background {
        position: relative;
        display: inline-block;
        width: $width;
        height: $height;
        background: var(--inactive);
        padding: $padding;
        border-radius: calc($height / 2);
        transition: 0.2s;
    }

    &__switch {
        position: relative;
        height: $height - $padding * 2;
        width: $height - $padding * 2;
        transition: 0.2s;
        border-radius: 50%;
        background: var(--toggle_switch);
        left: 0;
    }

    &__line {
        position: absolute;
        opacity: 0.5;
        height: 2px;
        width: 50%;
        background: white;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &--active {
        .toggleSwitch__background {
            background: var(--active);
        }
        .toggleSwitch__switch {
            left: #{($width - $padding * 2) - ($height - $padding * 2)};
        }
    }

    &--disabled {
        cursor: not-allowed;

        span {
            opacity: 0.5;
        }

        .toggleSwitch__background {
            background: var(--disabled);
        }
    }

    &--sub {
        padding-left: 20px;
    }

    &--busy {
        cursor: not-allowed;
        .toggleSwitch__background {
            // background: #dbdbdb;
        }
    }
}
