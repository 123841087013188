.grid {
    display: block;
    min-height: calc(100vh - 170px);

    .r {
        @include clearfix();
        display: block;
    }

    .c {
        display: block;
        float: left;
        margin: calc(2 * var(--gridbox_margins)) var(--gridbox_margins);

        &:nth-child(1) {
            width: calc(34% - (2 * var(--gridbox_margins)));
        }
        &:nth-child(2) {
            width: calc(66% - (2 * var(--gridbox_margins)));
        }
    }

    .r.r--chat .c {
        &:nth-child(1) {
            width: calc(25% - (2 * var(--gridbox_margins)));
        }
        &:nth-child(2) {
            width: calc(50% - (2 * var(--gridbox_margins)));
        }
        &:nth-child(3) {
            width: calc(25% - (2 * var(--gridbox_margins)));
        }
    }
}

.gridContainer {
    margin-bottom: calc(2 * var(--gridbox_margins));
    border: var(--border_width) var(--border_style) var(--gridbox_header_bg);
    background: white;
    border-radius: 0 0 var(--border_radius_super_narrow) var(--border_radius_super_narrow);

    header {
        font-size: $font_medium;
        position: relative;
        padding: var(--gridbox_header_padding);
        background: var(--gridbox_header_bg);
        color: var(--gridbox_header_text);
        letter-spacing: 0.08rem;
        cursor: pointer;
        font-weight: var(--gridbox_header_weight);

        &:after {
            content: '–';
            font-size: $font_large;
            position: absolute;
            right: 10px;
            font-weight: 700;
            line-height: 30px;
            top: 50%;
            transform: translate(0, -50%);
        }
    }

    main {
        padding: var(--gridbox_padding);
    }

    &--closed {
        main {
            display: none;
            height: 0;
        }

        header {
            &:after {
                content: '+';
            }
        }
    }

    &--alwaysOpened {
        header {
            cursor: not-allowed;
        }
    }

    &--isRemovable {
        header {
            &:after {
                content: '×';
            }
        }
    }

    &--hideCloseIcon {
        header {
            &:after {
                content: '';
            }
            cursor: default;
        }
    }

    &--isFocusable {
        .focus-wrapper {
            outline: none !important;
            .focus-inactive__container {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 35px;
                height: 100%;
                right: 40px;
                top: 50%;
                translate: 0 -50%;
            }

            .focus-inactive__container:hover {
                animation-delay: 0s;
                animation-duration: 0.2s;
                animation-name: enlarge;
                animation-fill-mode: forwards;
            }

            .focus-active__container {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 35px;
                top: 50%;
                right: 40px;
                height: 100%;
                translate: 0 -50%;

                &--changed-by-caller {
                    animation-delay: 0s;
                    animation-duration: 1.5s;
                    animation-name: pulse;
                    animation-fill-mode: forwards;
                }

                .focus-active, .focus-inactive {
                    overflow: visible;

                    circle {
                        fill: var(--focus_active);
                    }
                }
            }

            &--isDisabled {
                div,
                svg {
                    opacity: 0.5;
                    pointer-events: none;
                    animation-name: none;
                }
            }
        }
    }
}

@keyframes enlarge {
    0% {
        scale: 1;
    }
    100% {
        scale: 1.5;
    }
}

@keyframes pulse {
    0% {
        scale: 1;
    }
    25% {
        scale: 0.7;
    }
    50% {
        scale: 1;
    }
    75% {
        scale: 0.7;
    }
    100% {
        scale: 1;
    }
}
