@import "../../settings.scss";

.phonebook {
    width: 60px;

    &--name {
        width: 180px;
    }

    button {
        height: 39px;
        display: flex;
        align-items: center;
        background: var(--phonebook_dropdown);
        padding: 12px 8px 12px 8px;
        border-radius: var(--border_radius_super_narrow);
        border: none;
        color: var(--phonebook_dropdown_svg);
        font-size: 1rem;
        font-weight: bold;
        box-sizing: border-box;

        justify-content: center;

        svg {
            fill: var(--phonebook_dropdown_svg);
            width: 45px;
        }

        &:hover {
            background: var(--phonebook_dropdown);
            color: var(--phonebook_dropdown_svg);
            svg {
                fill: var(--phonebook_dropdown_svg);
            }
        }

        span {
            white-space: nowrap;
        }
    }

    .button--isCollapsed {
        border-radius: var(--border_radius_super_narrow) var(--border_radius_super_narrow) 0 0;
    }

    &__select {
        border: 2px solid var(--phonebook_dropdown);
        position: relative;
        display: none;
        min-width: 250px;
        z-index: 3;

        &--open {
            display: block;
        }
    }

    &__search {
        position: relative;
        background: #fff;
        padding: 10px;
        display: flex;
        align-items: center;
        border-bottom: 2px solid var(--phonebook_dropdown);
        z-index: 3;

        input {
            background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"></path><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path></svg>');
            background-repeat: no-repeat;
            background-position: 5% 60%;
            background-size: 24px;
            display: block;
            width: 100%;
            margin: 0;
            line-height: 1.5;
            padding: 6px 12px 6px 36px;
            border: 1px solid #b9b9b9;
            border-radius: 0;
            border-radius: 20px;
            font-size: 1rem;
            text-align: start;
            cursor: text;
            background-color: #fff;
            background-clip: padding-box;
            color: #010;
            transition: border-color 0.15s ease-in-out;
            box-shadow: none;
            box-sizing: border-box;
            text-indent: 25px;
        }

        input[type="text"]:focus {
            box-shadow: none;
        }

        svg {
            margin-right: 10px;
        }

        &:hover {
            background: var(--phonebook_dropdown);
        }
    }

    &__entries {
        max-height: calc(50vh - 120px);
        overflow: auto;

        :last-child {
            border-bottom: none;
        }
    }

    &__entry {
        padding: 10px;
        border-bottom: 2px solid var(--phonebook_dropdown);
        background: #fff;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        &:hover {
            background: var(--phonebook_dropdown);
            color: var(--phonebook_dropdown_svg);
        }
    }

    &__name {
        font-weight: bold;
        margin-bottom: 5px;

        word-break: break-word;
        word-wrap: break-word;
        hyphens: auto;
    }

    &__role {
        font-style: italic;
        margin-bottom: 5px;

        word-break: break-word;
        word-wrap: break-word;
        hyphens: auto;
    }
}
