.Login {
    padding: 60px 0;
}

.Login form {
    margin: 0 auto;
    max-width: 350px;
}

.Login {
    .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
