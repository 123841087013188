.alert {
  padding: 15px;
  line-height: 1.5;

  color: var(--font_color);
  background: $grey_light;
  
  border: 1px solid $grey_dark;
  border-radius: var(--border_radius);

  &--warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }

  &--success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  &--error, &--critical {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  &--info {
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db;
  }

  &--hint, &--pending {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
  }
}