.paintCanvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;

    width: 100%;
    height: 100%;

    display: none;

    &--is-visible {
        display: block;
    }

    &--isPaintingAllowed {
        background: white;
    } 

    &__background,
    &__canvas {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        opacity: 0;

        .paintCanvas--isPaintingAllowed & {
            opacity: 1;
            // width: auto;
            // left: 50%;
            // transform: translate(-50%);
        }
    }

    &__background {
        z-index: 1;
    }

    &__canvas {
        z-index: 2;
    }

    &__control.btn {
        position: absolute;

        bottom: 20px;
        right: 20px;
        height: 36px;
        width: 48px;

        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: var(--border_radius_narrow);

        z-index: 3;

        svg {
            width: 18px;
            height: 18px;
        }
    }

    &--caller {
        .paintCanvas__control {
            right: 50%;
            transform: translateX(50%);
        }
    }

    &__overlay {
        position: fixed;
        z-index: 899;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        background: rgba(0, 0, 0, 0.5);

        &Content {
            position: absolute;
            padding: 20px;
            background: white;
            width: 260px;
            left: 50%;
            top: 50%;
            border-radius: var(--border_radius);

            transform: translate(-50%, -50%);

            p {
                line-height: 1.3em;
            }
        }

        &Buttons {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
        }
    }
}
