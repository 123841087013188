@import '../../settings.scss';

.snapshots {
    padding: 10px;

    .connectionOverlay {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        // Hides connection overlay
        display: none;
    }

    &__button {
        text-align: center;
        display: flex;
        justify-content: space-around;

        & > div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .btn {
            display: flex;
            align-items: center;

            svg {
                margin-right: 5px;
                width: 24px;
            }
        }
    }

    .snapshotsPlaceholder {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        box-sizing: border-box;

        svg {
            max-width: 200px;
            max-height: 200px;
            width: 100%;
            height: 100%;
        }

        &--hidden {
            svg {
                display: none;
            }
        }
    }

    &__gallery {
        margin-top: 10px;

        a {
            display: inline-block;
            position: relative;
            width: calc(25% - 10px);
            margin: 10px 10px 10px 0;

            &:nth-child(4n) {
                margin-right: 0;
            }
        }

        img {
            width: 100%;
            height: 100%;
        }

        svg {
            position: absolute;
            width: 30px;
            bottom: 13px;
            right: 5px;
            z-index: 3;
            fill: var(--primary);
            background: white;
            padding: 3px;
            border-radius: var(--border_radius);
        }

        span {
            display: inline-block;
            margin: 5px 0 0;
            text-align: center;
            width: 100%;
            color: var(--font_color);
            text-decoration: none;
            font-size: 0.85em;
        }
    }

    &__hint {
        margin-top: 10px;
        small {
            font-size: 12px;
        }
    }
}
