.session-download {
    width: 100%;
    margin: -60px auto 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;

    &__loading {
        display: flex;
        align-items: center;
    }

    span {
        margin-left: 10px;
        display: inline-block;
    }

    div {
        .icon-animation {
            animation-delay: 0.3s;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-name: blink-animation-icon;

            svg {
                animation-delay: 0.3s;
                animation-duration: 1s;
                animation-iteration-count: infinite;
                animation-name: blink-animation-svg;
            }
        }
    }
}

@keyframes blink-animation-icon {
    0% {
        background-color: var(--primary);
        border: var(--border_width) var(--border_style) var(--primary);
    }

    50% {
        background-color: white;
        color: var(--primary);
    }
}

@keyframes blink-animation-svg {
    0% {
        fill: white;
    }
    
    50% {
        fill: var(--primary);
    }
}
