.screenshare-panel {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .dispatcherDashboard & {
        min-height: 150px;
        max-height: 150px;
    }

    &__placeholder {
        width: 100%;
        height: 100%;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            max-width: 150px;
            max-height: 150px;
            width: 70%;
            height: 70%;
        }
    }

    .screenshare-panel__placeholder {
        display: relative;

        svg {
            fill: #e6e7e8;
        }
    }

    &.screenshare-panel--active {
        .screenshare-container__wrapper {
            position: absolute;
            display: flex;
            width: 100%;
            top: 0;

            #screenshare-container {
                width: 100%;

                #screenshare-stream {
                    position: relative;
                    left: 50%;
                    transform: translateX(-50%);
                    max-height: 150px;
                    width: 100%;
                }
            }
        }

        .screenshare-panel__placeholder {
            display: none;
        }
    }
}
